
// import {
//     PlusOutlined
// } from "@ant-design/icons-vue"
export default {
    components: {
        // PlusOutlined
    },
    props: {
        dataSource: Array,
        order_id: String
    },
    data() {
        return {
            current: {},
            selection: [],
            visible: false,
            form: {}
        }
    },
    computed: {
        columns() {
            return [{
                    key: "index",
                    width: 48,
                    customRender: ({
                        index
                    }) => {
                        return index + 1
                    },
                },
                {
                    key: "sku",
                    dataIndex: "sku",
                    title: this.$t("SKU"),
                    sorter: true,
                    customRender: ({
                        text
                    }) => text || '-'
                },
                {
                    key: "line_itemfulfillment_status",
                    dataIndex: "line_itemfulfillment_status",
                    title: this.$t("状态"),
                    sorter: true,
                    width: 150,
                    customRender: ({
                        text
                    }) => text || '-'
                },
                {
                    key: "unit_cost",
                    dataIndex: "unit_cost",
                    title: this.$t("总额"),
                    sorter: true,
                    width: 100,
                    customRender: ({
                        text,
                        record
                    }) => {
                        return text + ' ' + record.currency_code
                    },
                    slots: {
                        customRender: "total"
                    }
                },
                {
                    key: "quantity",
                    dataIndex: "quantity",
                    title: this.$t("尺寸(L*W*H)"),
                    sorter: true,
                    width: 150,
                    customRender: ({
                        record
                    }) => `${record.length} * ${record.width} * ${record.height}`
                },
                {
                    key: "quantity",
                    dataIndex: "quantity",
                    title: this.$t("重量"),
                    sorter: true,
                    width: 100,
                    customRender: ({
                        record
                    }) => `${record.weight}`
                },
                {
                    key: "action",
                    title: this.$t("share.operation"),
                    width: 100,
                    slots: {
                        customRender: "action"
                    }
                }
            ]
        },
    },
    methods: {
        openEdit(row) {
            this.visible = true;
            this.current = row;
            this.form = Object.assign({}, {
                id: row.id,
                length: row.length,
                width: row.width,
                height: row.height,
                weight: row.weight
            });
        },
        save_form() {
            this.$http.post(`/user/store_order/update_store_order_line/${this.order_id}`, {
                lines: [this.form]
            }).then(res => {
                if (res.data.code == 0) {
                    Object.assign(this.dataSource.find(item => item.id == this.form.id), this.form);

                    this.visible = false;
                    this.form = {};
                    this.current = {};
                    this.$message.success("操作成功")
                } else {
                    this.$message.error("操作失败")
                }
            }).catch(e => {
                this.$message.error("操作失败")
                console.error(e);
            })
        }
    }
}
